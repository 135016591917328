//
//
//
//

import {mapGetters, mapState} from "vuex";

  export default {
    name: "default",
    computed: {
      isAgreeDocumentRoute() {
        return this.$router.currentRoute.name === 'agree-document'
      },
      ...mapState([
        'currentStudent',
      ]),
      ...mapGetters([
        'isContent',
      ])
    },
  }
